.form-check {
	text-align: left;
	&-input {
		+ label {
			display: inline-block;
			font-weight: 600;
			text-align: left;
			padding-left: 1rem;
			font-size: 1rem;
		}
		&:focus {
			border-color: $color-black;
			+ label {
				color: $color-black;
			}
		}
	}
}
