$color-success-bg: #51a351;
$color-success-text: #51a351;
$color-error-bg: #f8d7da;
$color-error-text: #79282c;
$color-info-bg: #2f96b4;
$color-info-text: #2f96b4;
$color-warning-bg: #fdf2d1;
$color-warning-text: #624d17;

.toast {
	// toaster positioning
	&-center-center {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&-top-center {
		top: 1rem;
		right: 0;
		width: 100%;
	}
	&-bottom-center {
		bottom: 0;
		right: 0;
		width: 100%;
	}
	&-top-full-width {
		top: 0;
		right: 0;
		width: 100%;
	}
	&-bottom-full-width {
		bottom: 0;
		right: 0;
		width: 100%;
	}
	&-top-left {
		top: 0.75rem;
		left: 0.75rem;
	}
	&-top-right {
		top: 0.75rem;
		right: 0.75rem;
	}
	&-bottom-right {
		right: 0.75rem;
		bottom: 0.75rem;
	}
	&-bottom-left {
		bottom: 0.75rem;
		left: 0.75rem;
	}
	// toast status
	&-success {
		background-color: $color-success-bg;
		color: $color-success-text;
		.toast {
			&-message {
				color: $color-success-text;
			}
			&-close-button {
				color: $color-success-text;
			}
		}
	}
	&-error {
		background-color: $color-error-bg;
		color: $color-error-text;
		.toast {
			&-message {
				color: $color-error-text;
			}
			&-close-button {
				color: $color-error-text;
			}
		}
	}
	&-info {
		background-color: $color-info-bg;
		color: $color-info-text;
		.toast {
			&-message {
				color: $color-info-text;
			}
			&-close-button {
				color: $color-info-text;
			}
		}
	}
	&-warning {
		background-color: $color-warning-bg;
		color: $color-warning-text;
		.toast {
			&-message {
				color: $color-warning-text;
			}
			&-close-button {
				color: $color-warning-text;
			}
		}
	}

	// toast styles
	&-title {
		font-weight: bold;
	}

	&-message {
		font-size: 1rem;
		line-height: 1.25rem;
		word-wrap: break-word;
		font-weight: 500;
	}
	&-close-button {
		position: relative;
		float: right;
		font-size: 1rem;
		font-weight: bold;
		border: 0;
		background: transparent;
		@extend i;
		@extend .cat-x-close;
		span {
			display: none;
		}
	}
	&-close-button:hover,
	&-close-button:focus {
		text-decoration: none;
		cursor: pointer;
		opacity: 0.4;
	}
	/*Additional properties for button version
	 iOS requires the button element instead of an anchor tag.
	 If you want the anchor version, it requires `href="#"`.*/
	button.toast-close-button {
		padding: 0;
		cursor: pointer;
		background: transparent;
		border: 0;
	}
	&-container {
		pointer-events: none;
		position: fixed;
		z-index: 999999;
		.ngx-toastr {
			position: relative;
			overflow: hidden;
			margin: 0 0 6px;
			padding: 1rem;
			width: $container-max-width - 2 * $container-padding-x - 2 * $container-gutter-width;
			@media screen and (max-width: $container-max-width) {
				width: 100%;
			}
			border-radius: 0.875rem;
			background-image: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row-reverse;
		}
		* {
			box-sizing: border-box;
		}
		.ngx-toastr:hover {
			opacity: 1;
			cursor: pointer;
		}
		&.toast {
			&-top-center .ngx-toastr,
			&-bottom-center .ngx-toastr {
				margin-left: auto;
				margin-right: auto;
			}
			&-top-full-width .ngx-toastr,
			&-bottom-full-width .ngx-toastr {
				width: 96%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		/* Responsive Design */
		@media all and (max-width: 240px) {
			.ngx-toastr.div {
				padding: 8px 8px 8px 50px;
				width: 11em;
			}
		}
		@media all and (min-width: 241px) and (max-width: 480px) {
			.ngx-toastr.div {
				padding: 8px 8px 8px 50px;
				width: 18em;
			}
		}
		@media all and (min-width: 481px) and (max-width: 768px) {
			.ngx-toastr.div {
				padding: 15px 15px 15px 50px;
				width: 25em;
			}
		}
	}
	.toast-progress {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 4px;
		background-color: #000000;
		opacity: 0.4;
	}
}

.ngx-toastr {
	pointer-events: auto;
}
