@include media-breakpoint-down("sm") {
  .modal {
    &-content {
      margin-top: 3.5rem + 1.5rem;
    }
    &-body {
      margin-top: 0;
    }
    &-close {
      top: 0;
      right: auto;
      left: 100%;
      left: calc(100% + 1.5rem);
    }
  }
}
