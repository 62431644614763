@import "./mixins/font.scss";

// 100 Thin (Hairline)
// 200 ExtraLight (UltraLight)
// 300 Light
// 400 Normal
// 500 Medium
// 600 SemiBold (DemiBold)
// 700 Bold
// 800 ExtraBold (UltraBold)
// 900 Black (Heavy)

@include font-face(
	$font-american-captain,
	"../../../../assets/font/AmericanCaptain/AmericanCaptain",
	400,
	null,
	otf ttf
);

@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-Bold", 700, null, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-BoldItalic", 700, italic, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-Regular", 400, null, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-RegularItalic", 400, italic, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-Medium", 500, null, ttf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-MediumItalic", 500, italic, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-SemiBold", 600, null, otf);
@include font-face($font-avenir, "../../../../assets/font/Avenir/Avenir-SemiBoldItalic", 600, italic, otf);

@include font-face(
	$font-avenir-arabic,
	"../../../../assets/font/AvenirNextArabic/AvenirNextArabic-Bold",
	700,
	null,
	otf
);

@include font-face(
	$font-basic-commercial,
	"../../../../assets/font/BasicCommercial/BasicCommercialSRPro-Bold",
	700,
	null,
	otf
);

@include font-face($font-fs-koopman, "../../../../assets/font/FsKoopman/FSKoopman-Black", 900, null, otf);

@include font-face($font-garage-gothic, "../../../../assets/font/GarageGothic/GarageGothic-Black", 900, null, otf);
@include font-face($font-garage-gothic, "../../../../assets/font/GarageGothic/GarageGothic-Bold", 700, null, otf);
@include font-face(
	$font-garage-gothic,
	"../../../../assets/font/GarageGothic/GarageGothic-Regular",
	900,
	null,
	otf ttf
);

@include font-face($font-graymalkin, "../../../../assets/font/graymalkin/graymalkin-bold-laser", 600, null, ttf);
@include font-face($font-graymalkin, "../../../../assets/font/graymalkin/graymalkin-bold", 700, null, ttf);
@include font-face($font-graymalkin, "../../../../assets/font/graymalkin/graymalkin-bold-border", 800, null, ttf);

@include font-face($font-lubalin, "../../../../assets/font/Lubalin/Lubalin-Bold", 700, null, otf ttf);

@include font-face($font-oduda, "../../../../assets/font/Oduda/Oduda-Bold", 700, null, otf);
@include font-face($font-oduda, "../../../../assets/font/Oduda/Oduda-Light", 300, null, otf);
@include font-face($font-oduda, "../../../../assets/font/Oduda/Oduda-Semi-Bold", 500, null, otf);
@include font-face($font-oduda, "../../../../assets/font/Oduda/Oduda", 400, null, otf);

@include font-face($font-rodger, "../../../../assets/font/Rodger/Rodger-Black", 900, null, otf);
@include font-face($font-rodger, "../../../../assets/font/Rodger/Rodger-Bold", 700, null, otf);
@include font-face($font-rodger, "../../../../assets/font/Rodger/Rodger-Light", 300, null, otf);
@include font-face($font-rodger, "../../../../assets/font/Rodger/Rodger-Thin", 100, null, otf);
@include font-face($font-rodger, "../../../../assets/font/Rodger/Rodger", 400, null, otf);

@include font-face($font-swiss721, "../../../../assets/font/Swiss721/Swiss721BT-Bold", 700, null, otf);

@include font-face(
	$font-typography-of-coop,
	"../../../../assets/font/TypographyofCoop/TypographyofCoop-Black",
	900,
	null,
	otf
);

@include font-face($font-vag-rounded, "../../../../assets/font/VAGRounded/VAGRoundedLTCYR-Bold", 900, null, otf);
