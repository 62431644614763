[appspiner] {
	position: relative;
}
.spinner-box {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba($color-white, 0.99);
	display: none;
	align-items: flex-start;
	justify-content: center;
	z-index: 99999999;
	&.spinning {
		display: flex;
		.spinner-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100vh;
		}
		.spinner-icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 3.5rem;
			width: 3.5rem;
			background-color: $color-black;
			border-radius: 50%;
			margin-right: 1.25rem;
			i {
				color: $color-white;
				font-size: 1.75rem;
			}
		}
		span {
			font-size: 2.5rem;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 0.2;
	}
	50% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.9);
		opacity: 0.2;
	}
}
