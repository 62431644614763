$color-white: #ffffff;
$color-black: #000103;
$color-yellow: #fff03b;
$color-purple: #6a4ade;
$color-pink: #e91087;
$color-red-500: #e24d63;
$color-red: $color-red-500;
$color-red-700: #fd122d;
$color-blue: #0e9cde;
$color-navy: #00628d;
$color-orange: #fba541;
$color-green: #14bfa0;
$color-gray-100: #f2f2f2;
$color-gray-200: #a5a2a5;
$color-gray-500: #5a5a5a;
$color-gray-700: #282725;

$font-avenir: Avenir;
$font-avenir-arabic: AvenirNextArabic;
$font-lubalin: Lubalin;
$font-american-captain: AmericanCaptain;
$font-basic-commercial: BasicCommercial;
$font-fs-koopman: FSKoopman;
$font-garage-gothic: GarageGothic;
$font-graymalkin: graymalkin;
$font-oduda: Oduda;
$font-rodger: Rodger;
$font-swiss721: Swiss721;
$font-rodger: Rodger;
$font-typography-of-coop: TypographyofCoop;
$font-vag-rounded: VAGRounded;

$font-primary: $font-avenir, Helvetica, sans-serif;
$font-secondary: $font-lubalin, sans-serif;

$font-family-base: $font-primary;
$headings-font-family: $font-secondary;
$btn-font-size: 1.25rem;
$btn-padding-y: 0.5rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 0.875rem;
$box-shadow-container: 0 3px 8px rgba($color-black, 0.3);
$box-shadow-input: 0 3px 6px rgba(#00000029, 0.3);
$modal-content-border-radius: $border-radius-lg;
$btn-outline-border-width: 0.25rem;

$form-label-margin-bottom: 0.25rem;
$form-label-font-size: 0.6875rem;
$form-label-font-style: null !default;
$form-label-font-weight: 600;
$form-label-color: $color-black;

$input-padding-y: 0.625rem;
$input-padding-x: 1.5rem;
$input-font-family: $font-avenir;
$input-font-size: 0.8125rem;
$input-font-weight: 600;
$input-line-height: 1.25rem;

$input-color: $color-gray-200;
$input-border-color: $color-gray-200;
$input-border-width: 2px;
$input-box-shadow: $box-shadow-input;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$input-focus-bg: $color-white;
$input-focus-border-color: $color-black;
$input-focus-color: $color-black;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$input-placeholder-color: $color-gray-200;

$form-check-input-width: 1.5rem;
$form-check-margin-bottom: 1rem;
$form-check-label-color: $color-gray-500;
$form-check-label-cursor: pointer;

$form-check-input-bg: $color-white;
$form-check-input-border: 2px solid $color-gray-200;
$form-check-input-border-radius: 0;
$form-check-radio-border-radius: 0;
$form-check-input-focus-border: $color-black;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-color: $color-gray-200;
$form-check-input-checked-bg-color: $color-white;
$form-check-input-checked-border-color: $color-gray-200;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$color-black}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$color-black}'/></svg>");

$form-check-input-indeterminate-color: $color-black;
$form-check-input-indeterminate-bg-color: $color-white;
$form-check-input-indeterminate-border-color: $color-black;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;

$form-check-inline-margin-end: 1rem;
$enable-grid-classes: false;

$form-feedback-icon-valid-color: $color-green;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $color-red-700;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='31.357' height='31.357' viewBox='0 0 31.357 31.357' fill='none'><path id='Shape' d='M15.679,0A15.679,15.679,0,1,0,31.357,15.679,15.725,15.725,0,0,0,15.679,0Zm1.568,23.518H14.111V20.382h3.136v3.136Zm0-6.271H14.111V7.839h3.136v9.407Z' fill='#{$form-feedback-icon-invalid-color}' fill-rule='evenodd' /></svg>");

$form-validation-states: (
	"valid": (
		"color": $form-feedback-valid-color,
		"icon": $form-feedback-icon-valid,
	),
	"invalid": (
		"color": $form-feedback-invalid-color,
		"icon": $form-feedback-icon-invalid,
	),
);

$container-max-width: 60rem;
$container-padding-x: 1.5rem;
$container-gutter-width: 1.5rem;
