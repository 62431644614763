@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./app/core/style/variables.scss";
@import "./app/core/style/icons.scss";
@import "./app/core/style/maps.scss";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/caroimportl";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/mixins/_container";

// @import "~bootstrap/scss/utilities/api";

@import "./app/core/style/typography.scss";
@import "./app/core/style/fonts.scss";
@import "./app/core/style/_feedback.scss";
@import "./app/core/style/_input.scss";
@import "./app/core/style/_checkbox.scss";
@import "./app/core/style/modal.scss";
@import "./app/core/style/utils.scss";
@import "./app/core/style/toaster.scss";

@import "./app/core/component/spinner/spinner.scss";

// todo: split to function import file
html {
	font-size: 16px;
}
pre {
	padding: 0.5rem;
	background: #f1f1f1;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
}
