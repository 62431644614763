.form-check > app-feedback-form-group {
	.invalid-feedback {
		padding: 0 1rem;
	}
}

.form-group {
	&.invalid {
		input {
			border-color: $color-red-700;
		}
	}
}
.form-check {
	&.invalid {
		input {
			border-color: $color-red-700;
		}
	}
}
