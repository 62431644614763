$icomoon-font-family: "turner" !default;
$icomoon-font-path: "fonts" !default;

$cat-error: "\e907";
$cat-emoji_blink_icon: "\e90a";
$cat-expired_entry_close: "\e90b";
$cat-sent_message: "\e90c";
$cat-round_arrow: "\e90d";
$cat-sending_email: "\e90e";
$cat-confetti: "\e90f";
$cat-prize: "\e908";
$cat-spinner1: "\e909";
$cat-videos: "\e900";
$cat-games: "\e901";
$cat-play: "\e902";
$cat-arrow-down: "\e903";
$cat-arrow-right: "\e904";
$cat-arrow-left: "\e905";
$cat-x-close: "\e906";
$cat-spinner: "\e981";

@font-face {
	font-family: "#{$icomoon-font-family}";
	src: url("../../../assets/font/Icons/#{$icomoon-font-family}.ttf?z57oo8") format("truetype"),
		url("../../../assets/font/Icons/#{$icomoon-font-family}.woff?z57oo8") format("woff"),
		url("../../../assets/font/Icons/#{$icomoon-font-family}.svg?z57oo8##{$icomoon-font-family}") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "#{$icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.cat-error {
	&:before {
		content: $cat-error;
	}
}
.cat-emoji_blink_icon {
	&:before {
		content: $cat-emoji_blink_icon;
	}
}
.cat-expired_entry_close {
	&:before {
		content: $cat-expired_entry_close;
	}
}
.cat-sent_message {
	&:before {
		content: $cat-sent_message;
	}
}
.cat-round_arrow {
	&:before {
		content: $cat-round_arrow;
	}
}
.cat-sending_email {
	&:before {
		content: $cat-sending_email;
	}
}
.cat-confetti {
	&:before {
		content: $cat-confetti;
	}
}
.cat-prize {
	&:before {
		content: $cat-prize;
	}
}
.cat-spinner1 {
	&:before {
		content: $cat-spinner1;
	}
}
.cat-videos {
	&:before {
		content: $cat-videos;
	}
}
.cat-games {
	&:before {
		content: $cat-games;
	}
}
.cat-play {
	&:before {
		content: $cat-play;
	}
}
.cat-arrow-down {
	&:before {
		content: $cat-arrow-down;
	}
}
.cat-arrow-right {
	&:before {
		content: $cat-arrow-right;
	}
}
.cat-arrow-left {
	&:before {
		content: $cat-arrow-left;
	}
}
.cat-x-close {
	&:before {
		content: $cat-x-close;
	}
}
.cat-spinner {
	&:before {
		content: $cat-spinner;
	}
}
