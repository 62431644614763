.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 2.375rem - $form-label-margin-bottom - $form-label-font-size;
}
.form-label {
	display: flex;
	padding: 0 1.5rem;
	margin-bottom: 0.25rem;
}
.form-control {
	box-sizing: border-box;
	box-shadow: $box-shadow-input;
	margin-bottom: 0;
	&[type="text"],
	&[type="number"],
	&[type="email"] {
		padding: 1.5rem 1rem;
		background-color: $color-white;
		box-sizing: border-box;
		width: 100%;
		font-size: 1.375rem;
		line-height: 2rem;
		border-radius: 0;
		border-color: $color-gray-200;
		border-width: 2px;
		box-shadow: $box-shadow-input;
		&.form-control-sm {
			padding: 0.25rem 1.5rem;
			font-size: 0.875rem;
			border-radius: 0;
		}
		&::placeholder {
			color: $color-gray-200;
		}
		&.is-invalid {
			&:after {
				@extend i;
				@extend .cat-error;
			}
		}
	}
	&.is-invalid,
	&.invalid {
		border-color: $color-red-700;
	}
	&:focus {
		box-shadow: $box-shadow-input;
		border-color: $color-black;
	}
}
