h1 {
	font-size: 1.275rem;
	line-height: 1.6875rem;
	margin-top: 0;
	font-weight: 700;
	text-transform: uppercase;
	color: $color-black;
	@include media-breakpoint-up(md) {
		font-size: 2.5rem;
		line-height: 3rem;
	}
}
h2 {
	font-size: 1.125rem;
	line-height: 1.375rem;
	margin-top: 0;
	font-weight: 700;
	text-transform: none;
	color: $color-black;
	@include media-breakpoint-up(md) {
		font-size: 1.375rem;
		line-height: 1.6875rem;
	}
}
h3 {
	font-size: 1.125rem;
	line-height: 2.0625rem;
	margin-top: 0;
	font-weight: 700;
	color: $color-black;
	text-transform: none;
	@include media-breakpoint-up(md) {
		font-size: 1.375rem;
		line-height: 2.0625rem;
	}
}
h4 {
	font-size: 1.125rem;
	line-height: 1.5rem;
	margin-top: 0;
	font-weight: 700;
	text-transform: none;
	color: $color-gray-700;
	@include media-breakpoint-up(md) {
		font-size: 1.375rem;
		line-height: 2rem;
	}
}
h5 {
	font-size: 1.125rem;
	line-height: 2.0625rem;
	margin-top: 0;
	font-weight: 600;
	text-transform: none;
	color: $color-gray-700;
	@include media-breakpoint-up(md) {
		font-size: 1.375rem;
		line-height: 2rem;
	}
}
h6 {
	font-size: 0.8125rem;
	color: $color-gray-200;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.01;
	text-transform: none;
	@include media-breakpoint-up(md) {
		font-size: 0.8125rem;
		line-height: 1.25rem;
	}
}
p {
	font-size: 1rem;
	line-height: 1.25rem;
	margin-top: 0;
	font-weight: 600;
	text-transform: none;
	color: $color-black;
	@include media-breakpoint-up(md) {
		font-size: 1rem;
		line-height: 1.25rem;
	}
}
a {
	color: $color-pink;
	&:hover {
		color: $color-blue;
	}
}

.color-light {
	color: $color-gray-500;
}
.size-small {
	font-size: 0.875rem;
	line-height: 1rem;
}

.brand-none {
	h1,
	h2,
	.font-headline {
		font-family: $font-lubalin !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-avenir !important;
		font-weight: 500 !important;
	}
}

.cartoon-network {
	h1,
	h2,
	.font-headline {
		font-family: $font-lubalin !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-avenir !important;
		font-weight: 500 !important;
	}
}

.boomerang {
	h1,
	h2,
	.font-headline {
		font-family: $font-vag-rounded !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-swiss721, $font-vag-rounded !important;
	}
}

.boing-fr {
	h1,
	h2,
	.font-headline {
		font-family: $font-fs-koopman !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-basic-commercial !important;
	}
}

.boing-es {
	h1,
	h2,
	.font-headline {
		font-family: $font-fs-koopman !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-basic-commercial !important;
	}
}

.toonami {
	h1,
	h2,
	.font-headline {
		font-family: $font-graymalkin !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-american-captain !important;
	}
}

.toonix {
	h1,
	h2,
	.font-headline {
		font-family: $font-typography-of-coop !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-avenir !important;
		font-weight: 500 !important;
	}
}

.cartoonito {
	h1,
	h2,
	.font-headline {
		font-family: $font-rodger !important;
		font-weight: 900 !important;
	}
	h3,
	h4,
	h5,
	h6,
	p,
	.font-paragraph,
	.form-check-input + label,
	.toast-message,
	.form-label,
	.form-control[type="text"],
	.form-control[type="number"],
	.form-control[type="email"] {
		font-family: $font-oduda !important;
	}
}
